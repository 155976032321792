import React from 'react';

import VisibleLoadableBlock from '../VisibleLoadableBlock/VisibleLoadableBlock';
import ContentAreaItem from '../Models/ContentAreaItem.interface';

type PropType = {
  childItems: ContentAreaItem[];
  isVisible?: boolean;
  nestedContentArea?: boolean;
};

function ContentArea({
  childItems = [],
  isVisible = false,
  nestedContentArea = false,
}: PropType) {
  const renderTopComponents = (
    component: any,
    parentI: any,
    nextComponent: any,
    nestedBlock: any
  ) => {
    return component ? (
      <VisibleLoadableBlock
        {...component}
        isNested={nestedBlock}
        nextBlockProps={nextComponent}
        key={
          component && component.blockId ? component.blockId + parentI : parentI
        }
        loopIndex={parentI}
        isVisible={!!isVisible}
      >
        {component &&
          component.childItems &&
          component.childItems.map(
            (
              subComponent: React.ReactNode,
              i: number,
              arr: React.ReactNodeArray
            ) =>
              renderTopComponents(
                subComponent,
                parentI + '_' + i,
                arr[i + 1],
                true
              )
          )}
      </VisibleLoadableBlock>
    ) : (
      <React.Fragment key={parentI} />
    );
  };
  return (
    <>
      {childItems.map((component, i, arr) =>
        renderTopComponents(component, i, arr[i + 1], nestedContentArea)
      )}
    </>
  );
}

export default ContentArea;
