import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IS_SERVER_CONTEXT } from '../Configs/EnvConfig';

const useOnScreen = ({ ref, rootMargin = '0px', once = true }) => {
  const [isIntersecting, setIntersecting] = useState(false);
  const [observerObject, setObserverObject] = useState(false);

  useEffect(() => {
    const elm = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );
    if (elm) {
      observer.observe(elm);
    }
    setObserverObject(observer);
    return () => {
      !Object.isEmpty(elm) && observer.unobserve(elm);
    };
  }, [ref, rootMargin]);

  if (
    once &&
    isIntersecting &&
    observerObject &&
    !Object.isEmpty(ref.current)
  ) {
    observerObject.unobserve(ref.current);
  }

  return IS_SERVER_CONTEXT ? true : isIntersecting;
};

useOnScreen.propTypes /* remove-proptypes */ = {
  ref: PropTypes.shape({ current: PropTypes.element }),
  rootMargin: PropTypes.string,
  once: PropTypes.bool,
};

export default useOnScreen;
