import React from 'react';
// import styles from './LoadingBlock.module.scss';

import LoadingDots from '../LoadingDots/LoadingDots';

function LoadingBlock() {
  return (
    <div>
      <div>{/* <LoadingDots size={8} /> */}</div>
    </div>
  );
}

export default LoadingBlock;
