import React, { useRef } from 'react';
import loadable from '@loadable/component';
import { IS_SERVER_CONTEXT } from '../Configs/EnvConfig';
import useOnScreen from '../Hooks/useOnScreen';
import LoadingBlock from '../Loading/LoadingBlock/LoadingBlock';

//TODO refactor with correct blocktypes
type PropType = {
  blockType: any;
  isVisible: boolean;
};

const loadableComponents: any = {
  StandardBlock: loadable(() => import('../../StandardBlock/StandardBlock')),
  ImageLinkListingBlock: loadable(() =>
    import('../../Blocks/ImageLinkListingBlock/ImageLinkListingBlock')
  ),
  ImageLinkBlock: loadable(() => import('../Models/ImageLink/ImageLinkBlock')),
  IconBlock: loadable(() => import('../../Blocks/IconBlock/IconBlock')),
  LinkListBlock: loadable(() =>
    import('../../Blocks/LinkListBlock/LinkListBlock')
  ),
  DocumentListBlock: loadable(() =>
    import('../../Blocks/DocumentListBlock/DocumentListBlock')
  ),
  VideoBlock: loadable(() => import('../../Blocks/VideoBlock/VideoBlock')),
};

function VisibleLoadableBlock(props: PropType) {
  const ref = useRef<HTMLSpanElement>(null);
  const onScreen = useOnScreen({ ref });
  const { blockType, isVisible } = props;
  const LoadableComponent = loadableComponents[blockType];

  return LoadableComponent === undefined ? (
    <React.Fragment />
  ) : isVisible || onScreen || IS_SERVER_CONTEXT ? (
    <LoadableComponent fallback={<LoadingBlock />} {...props} />
  ) : (
    <span ref={ref} />
  );
}

export default VisibleLoadableBlock;
