import React from 'react';
import { styled } from '@glitz/react';
import { theme } from '../../Theme';
import { media } from '@glitz/core';
import { StyleOrStyleArray } from '@glitz/type';
import { GridSize } from '../../Enums/GridSize.enum';

type PropTypes = {
  children: React.ReactNode;
  type?: GridSize;
};

function Grid({ children, type, ...rest }: PropTypes) {
  let css = {};

  switch (type) {
    case GridSize.Three:
      css = Three;
      break;
    case GridSize.Four:
      css = Four;
      break;
    case GridSize.Usp:
      css = Usp;
      break;
    case GridSize.ContentContainer:
      css = Twelve;
      break;
    default:
      //Was the prevoius grid layout
      css = Four;
      break;
  }
  return (
    <Div css={css} {...rest}>
      {children}
    </Div>
  );
}

const Div = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
  gridGap: theme.large,
  padding: { x: theme.gamma },
});

const Twelve: StyleOrStyleArray = {
  width: '100%',
  maxWidth: theme.contentMaxWidth,
  margin: { x: 'auto', y: theme.great },
  padding: { y: theme.none, x: theme.none },
  gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
  gridColumnGap: theme.none,
  gridRowGap: theme.great,
  ...media(theme.mediaQuery.mediaMinMedium, {
    gridRowGap: theme.gamma,
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridRowGap: '78px',
    gridColumnGap: theme.gamma,
  }),
};

const Four: StyleOrStyleArray = {
  ...media(theme.mediaQuery.mediaMinSmall, {
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gridGap: theme.large,
  }),
  ...media(theme.mediaQuery.mediaMinMedium, {
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    gridGap: theme.large,
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    gridGap: theme.large,
  }),
  ...media(theme.mediaQuery.mediaMinHuge, {
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    gridGap: theme.great,
  }),
};

const Three: StyleOrStyleArray = {
  justifySelf: 'center',
  gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
  gridGap: theme.none,
  ...media(theme.mediaQuery.mediaMinMedium, {
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    gridGap: theme.large,
  }),
};

const Usp: StyleOrStyleArray = {
  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  gridGap: theme.large,
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    gridGap: theme.large,
  }),
};

export default Grid;
